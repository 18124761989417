export const setSignedFileForVerifyEu = (signedFile) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNED_FILE_FOR_VERIFY_EU',
        payload: signedFile
    })
}

export const setURLs = (urls) => (dispatch) => {
    dispatch({
        type: 'SET_URLS',
        payload: urls.serviceURLs
    })
}

export const setShowUploadpopup = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_UPLOAD_POPUP',
        payload: value
    })
}

export const setFileListEuVerify = (value) => (dispatch) => {
    dispatch({
        type: 'SET_FILE_LIST_EU_VERIFY',
        payload: value
    })
}

export const setSizeOfFilesEu = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SIZE_OF_FILES_EU',
        payload: value
    })
}

export const setEuVerifyResult = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EU_VERIFY_RESULT',
        payload: value
    })
}

export const setShowAdwancedReport = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_ADVANCED_REPORT',
        payload: value
    })
}

export const setSelectedCa = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_CA',
        payload: value
    })
}