export const getURLs = () => (dispatch) => {

    return fetch(process.env.PUBLIC_URL + "/config.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const verifyEs = (url, data, token) => (dispatch) => {
    var requestUrl, header;

    if (token !== undefined && token.length > 0) {
        header = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
    } else {
        header = {
          'Content-Type': 'application/json'
        }
    }
        
    requestUrl = url + "/validator/es";

    return fetch(requestUrl, {
        method: 'PUT',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then(response => {
        if(response.ok) {
            return response.json()
          } else {
            return response.json().then(json => {
              return {
                contentType: response.headers.get("Content-Type"),
                json: json
              }
            })
          }  
    });
}