import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import i18next from 'i18next'

// import Tabs from './Tabs'
// import Pane from './Tab'

import EUVerifyContainer from '../containers/EUVerifyContainer'
// import UKVerifyContainer from '../containers/UKVerifyContainer'

class Body extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
    		selected: 0
    	}
	}

	render() {
		return (
			<div id="allTabsContainer">
				<EUVerifyContainer />
				{/*<Tabs selected={0}>
					<Pane label={i18next.t("EUSign")}>
						<EUVerifyContainer />
					</Pane>

					<Pane label={i18next.t("UKSign")}>
						<UKVerifyContainer />
					</Pane>
				</Tabs>*/}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);