import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {  } from "../actions/localStates"
import {  } from "../actions/api"

import Body from '../components/Body';

class BodyContainer extends Component {

	render() {
		return (
			<div className="container container-width" style={{paddingTop: "10px"}}>
				<Body />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
 
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer);