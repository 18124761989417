import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setEuVerifyResult } from "../actions/localStates"
import { verifyEs } from "../actions/api"

import EUVerifyView from '../components/EUVerifyView';

class EUVerifyContainer extends Component {

    constructor(props) {
    super(props);

        this.child = React.createRef();

        this.verifyData = this.verifyData.bind(this);
    }

    verifyData () {
        var _this = this, data, file, readedData, tempArr = [];

        function getBase64(file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
              if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
              }
              resolve(encoded);
            };
            reader.onerror = error => reject(error);
          });
        }

        async function Main() {
            file = _this.props.verifyEu.signedFile;

            if (file.name === undefined) {
                readedData = file.base64Data
            } else {
                readedData = await getBase64(file);
                console.log(await getBase64(file));
            }
        }

        Main()
        .then(() => {

            async function readSignsData() {
                for (var i = 0; i < _this.props.verifyEu.fileListEu.length; i += 1) {
                    const file = _this.props.verifyEu.fileListEu[i];
                    tempArr.push({
                        "base64Data" : await getBase64(file),
                        "dataId": file.name
                    });
                    console.log(await getBase64(file));
                }
            }

            if (_this.props.verifyEu.fileListEu !== null && _this.props.verifyEu.fileListEu.length > 0) {
                readSignsData()
                .then(() => {
                    data = {
                        "signedDocument" : readedData,
                        "originalDocuments" : tempArr,
                        "signatureId" : 0,
                        "tokenExtractionStrategy" : null,
                        "validationReportTypes" : [
                            "CIPHER_SIMPLE_JSON_REPORT",
                            "ETSI_VALIDATION_REPORT"
                        ]
                    }

                    _this.props.actions.verifyEs(_this.props.verifyEu.selectedCA === 'eu' ? this.props.base.baseUrlEu : _this.props.base.baseUrlUk, data, _this.props.base.jwtToken)
                    .then((response) => {
                        console.log(response)
                        if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
                            var msg = response.json.message + "\n" + response.json.techMessage
                            alert(msg);
                        } else {
                            _this.props.actions.setEuVerifyResult(response)
                        }
                    })
                })
            } else {
                data = {
                    "signedDocument" : readedData,
                    "originalDocuments" : tempArr,
                    "signatureId" : 0,
                    "tokenExtractionStrategy" : null,
                    "validationReportTypes" : [
                        "CIPHER_SIMPLE_JSON_REPORT",
                        "ETSI_VALIDATION_REPORT"
                    ]
                }

                this.props.actions.verifyEs(this.props.verifyEu.selectedCA === 'eu' ? this.props.base.baseUrlEu : this.props.base.baseUrlUk, data, _this.props.base.jwtToken)
                .then((response) => {
                    console.log(response)
                    if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
                        var msg = response.json.message + "\n" + response.json.techMessage
                        alert(msg);
                    } else {
                        this.props.actions.setEuVerifyResult(response)
                    }
                })
            }

            
        })
    }

	render() {
		return (
			<div style={{marginBottom: "12px"}}>
				<EUVerifyView verifyData={this.verifyData} ref={this.child} />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        verifyEu: state.eUVerifyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        verifyEs, setEuVerifyResult
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EUVerifyContainer);