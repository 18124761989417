export const formatDate = (dateInString) => (dispatch) => {
	var options = {
	    timeZone: "Europe/Kiev",
	    "day": "numeric",
        "month": "numeric",
        "year": "numeric",
        "hour": "2-digit",
        "minute": "2-digit",
        "second": "2-digit"
	};

	var formatter = new Intl.DateTimeFormat([], options);

	var UTCTime = dateInString;
	var localTime = formatter.format(new Date(UTCTime));

	return localTime;
}

export const fromBytes = (digit) => (dispatch) => {
	var result,
			gigabyte  = 1073741824,
			megabyte = 1048576,
			kilobyte = 1024

	function div(val, by) {
	    return (val - val % by) / by;
	}

	function isInt(n) {
		return Number(n) === n && n % 1 === 0
	}

	if (div(digit, gigabyte) > 0 && isInt(div(digit, gigabyte))) {
		result = {
			value: (digit / gigabyte).toFixed(2),
			unit: "gigabyte"
		}
	} else if (div(digit, megabyte) > 0 && isInt(div(digit, megabyte))) {
		result = {
			value: (digit / megabyte).toFixed(2),
			unit: "megabyte"
		}
	} else if (div(digit, kilobyte) > 0 && isInt(div(digit, kilobyte))) {
		result = {
			value: (digit / kilobyte).toFixed(2),
			unit: "kilobyte"
		}
	} else {
		result = {
			value: digit,
			unit: "byte"
		}
	}

	return result;
}