const uKVerifyDefaultState = {

}

const uKVerifyReducer = (state = uKVerifyDefaultState, { type, payload }) => {

    switch (type) {

        default:
            return state;
    }

}

export default uKVerifyReducer