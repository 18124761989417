import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setShowUploadpopup, setFileListEuVerify, setSizeOfFilesEu } from "../actions/localStates"

import i18next from 'i18next'

import Dropzone from 'react-dropzone';
const dropzoneRef = createRef();

class UploadPopup extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
      		files: this.props.verify.fileListEu || [],
      		sizeOfFiles: 0
	    }

	    this.handleOnDrop = this.handleOnDrop.bind(this);
	}

	handleOnDrop (files) {
		let fileList = this.state.files;
		let allFilesSize = this.state.sizeOfFiles;

		if (files[0] !== undefined && files.length > 0) {
			if (allFilesSize < 104857600) {
				for (var i = 0; i < files.length; i += 1) {
					if (allFilesSize + (files[i].size || files[i].fileSize) < 104857600) {
						fileList.push(files[i])
						allFilesSize += files[i].size
						this.setState({"sizeOfFiles": allFilesSize})
						this.props.actions.setSizeOfFilesEu(allFilesSize)
					}
				}
				this.setState({"files": fileList}, () => {
					this.props.actions.setFileListEuVerify(fileList);
					this.props.actions.setShowUploadpopup(false)
				})
			}
		}
	}

	closeShowUploadPopup () {
		this.props.actions.setShowUploadpopup(false)
	}

	render() {
		return (
			<div className='popup'>
            	<div className="popup_inner_key">
            		<Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop} multiple={true}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} style={{cursor: "pointer", margin: "16px", minWidth: "400px", minHeight: "280px"}} className="dropzone" id="dropFile">
                                <input {...getInputProps()} />
                                <p style={{cursor: "pointer", marginTop: "120px"}}>{i18next.t("dragNDropTitle")}</p>
                            </div>
                        )}
                    </Dropzone>
                    {/*<button className="btn btn-default" style={{"cursor": "pointer", marginRight: "10px", marginBottom: "10px", float: "right"}} onClick={this.closeShowUploadPopup.bind(this)}>{i18next.t("addFiles")}</button>*/}
            		<button className="btn btn-default" style={{"cursor": "pointer", marginRight: "16px", marginBottom: "16px", float: "right"}} onClick={this.closeShowUploadPopup.bind(this)}>{i18next.t("cancel")}</button>
            	</div>
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        verify: state.eUVerifyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setShowUploadpopup, setFileListEuVerify,
    	setSizeOfFilesEu
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPopup);