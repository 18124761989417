import { combineReducers } from 'redux';

import uKVerifyReducer from './uKVerifyReducer'
import eUVerifyReducer from './eUVerifyReducer'

const baseState = {
    baseUrlEu : "",
    baseUrlUk : "",
    clientStyle: "default",
    jwtToken: ""
}

const base = (state = baseState, { type, payload }) => {

    switch (type) {

        case 'SET_URLS': {
            return {
                ...state,
                baseUrlEu: payload.baseUrlEu || "https://ccs-dev-api.cipher.com.ua/ccs/eues/api/v1",
                baseUrlUk: payload.baseUrlUk || "https://ccs-dev-api.cipher.com.ua/ccs/euesua/api/v1",
                clientStyle: payload.clientStyle || "default",
                jwtToken: payload.jwtToken || ""
            }
        }

        default:
            return state;
    }

}

export default combineReducers({
    base: base,
    uKVerifyReducer,
    eUVerifyReducer
});