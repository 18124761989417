const eUVerifyDefaultState = {
	signedFile: null,
	showUploadPopup: false,
	fileListEu: null,
	sizeOfFiles: 0,
	euVerifyResult: null,
	showAdvancedReport: false,
	selectedCA: "uk"
}

const eUVerifyReducer = (state = eUVerifyDefaultState, { type, payload }) => {

    switch (type) {

    	case "SET_SIGNED_FILE_FOR_VERIFY_EU":
    		return {
		        ...state,
		        signedFile: payload
		    }

		case "SET_SHOW_UPLOAD_POPUP":
			return {
		        ...state,
		        showUploadPopup: payload
		    }

		case "SET_FILE_LIST_EU_VERIFY":
			return {
		        ...state,
		        fileListEu: payload
		    }

		case "SET_SIZE_OF_FILES_EU":
			return {
		        ...state,
		        sizeOfFiles: payload
		    }

		case "SET_EU_VERIFY_RESULT":
			return {
		        ...state,
		        euVerifyResult: payload
		    }

		case "SET_SHOW_ADVANCED_REPORT":
			return {
		        ...state,
		        showAdvancedReport: payload
		    }

		case "SET_SELECTED_CA":
			return {
		        ...state,
		        selectedCA: payload
		    }

        default:
            return state;
    }

}

export default eUVerifyReducer