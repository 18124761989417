import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import '../css/eu-sign.css';

import {  } from '../actions/helpers';
import { getURLs } from '../actions/api'
import { setURLs } from '../actions/localStates'

import i18next from 'i18next'

import BodyContainer from './BodyContainer'
import HeaderContainer from './HeaderContainer'

class App extends Component {

	componentDidMount() {
		let _this = this

		this.props.actions.getURLs()
		.then((response) => {
			_this.props.actions.setURLs(response)
		})
	}

	goToMainSite () {
		window.open("https://cipher.com.ua/uk", '_blank').focus();
	}

	render() {
		return (
			<div>
				<div className="wrapper">
					<HeaderContainer />
					<BodyContainer />
				</div>
				<div className="footer row row-0 row-padding-0">
                    
					<div className="col-lg-2 col-md-3 col-sm-12 col-xs-12 offset-3">
                        <span className="" style={{marginBottom: "0px"}}>
                            ©2023 Шифр-CaaS-eIDAS
                        </span>
                        <p onClick={this.goToMainSite} style={{marginBottom: "0px", cursor: "pointer"}}>
                            Розроблено: Сайфер
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <span className="" style={{marginBottom: "0px"}}>
                            {i18next.t("address")}
                        </span>
                        <p className="">
                            {i18next.t(this.props.base.clientStyle + "Address")}
                        </p>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <span className="" style={{marginBottom: "0px"}}>
                            {i18next.t("mailLabel")}
                        </span>
                        <p className="">
                            {i18next.t(this.props.base.clientStyle + "mailText")}
                        </p>
                    </div>
                    {/*<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <span className="" style={{marginBottom: "0px"}}>
                            {i18next.t("webSourceTitle")}
                        </span>
                        <p onClick={this.goToSource} className="" style={{cursor: "pointer"}}>
                            {i18next.t("webSourceLabel")}
                        </p>
                    </div>*/}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	getURLs, setURLs
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);